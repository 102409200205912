<template>
  <span v-if="!isSplit">
    <span
      class="tw-inline-block"
      :style="`${scaleFactor ? `transform: scale(${scaleFactor})` : ''}`"
      >{{ formattedValue }}</span
    >
  </span>
  <span v-else>
    <span
      class="tw-inline-block"
      :style="`${scaleFactor ? `transform: scale(${scaleFactor})` : ''}`"
    >
      <span
        v-for="(part, index) in formattedValueParts"
        :key="`${part.value}-${index}`"
        :class="classForPartType(part.type)"
      >
        <template v-if="index !== 0">{{ '&nbsp;' }}</template
        >{{ part.value }}
      </span>
    </span>
  </span>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

import { useAuthStore } from '@/stores/auth'
import { lerp } from '@/utils/mathUtils'
import { isDefined } from '@/utils/typeUtils'

const props = withDefaults(
  defineProps<{
    value?: number | null
    locale?: string
    currency?: string
    minimumFractionDigits?: number
    maximumFractionDigits?: number
    currencyDisplay?: 'symbol' | 'code' | 'name'
    valueClass?: string
    currencyClass?: string
    scale?: { minCharacters: number; maxCharacters: number; scaleAtMinCharacters: number; scaleAtMaxCharacters: number }
  }>(),
  {
    value: 0,
    locale: undefined,
    currency: '',
    minimumFractionDigits: undefined,
    maximumFractionDigits: undefined,
    currencyDisplay: 'symbol',
    valueClass: undefined,
    currencyClass: undefined,
    scale: undefined,
  },
)

const format = computed(() => {
  return {
    style: props.currency ? 'currency' : 'decimal',
    minimumFractionDigits: props.minimumFractionDigits,
    maximumFractionDigits: props.maximumFractionDigits,
    ...(props.currency
      ? {
          currency: props.currency,
          currencyDisplay: props.currencyDisplay,
        }
      : {}),
  }
})

const { currentNumberLocale } = storeToRefs(useAuthStore())

const formattedValue = computed(() => {
  if (typeof props.value !== 'number') {
    if (props.value !== null) console.error(`CurrencyFormat: value is not a number: ${props.value}`)
    return ''
  }

  return new Intl.NumberFormat(props.locale || currentNumberLocale.value, format.value).format(props.value)
})

const formattedValueParts = computed(() => {
  const parts = formattedValue.value.split(/\s|&nbsp;/g)
  if (parts.length < 2) return undefined

  const typedParts = parts.map((part) => ({
    value: part,
    type: isNaN(Number(part.replace(',', '.'))) ? ('currency' as const) : ('value' as const),
  }))
  return typedParts.find((part) => part.type === 'value') ? typedParts : undefined
})

const shouldBeSplit = computed(() => isDefined(props.currencyClass) || isDefined(props.valueClass))
const canBeSplit = computed(() => isDefined(formattedValueParts.value))
const isSplit = computed(() => shouldBeSplit.value && canBeSplit.value)

const classForPartType = (type: NonNullable<(typeof formattedValueParts)['value']>[number]['type']) => {
  if (type === 'value') {
    return props.valueClass
  } else if (type === 'currency') {
    return props.currencyClass
  } else {
    return undefined
  }
}

const scaleFactor = computed(() => {
  if (!isDefined(props.scale)) return undefined
  const numberOfCharacters = formattedValue.value.length
  const factor =
    (numberOfCharacters - props.scale.minCharacters) / (props.scale.maxCharacters - props.scale.minCharacters)
  const interpolatedFactor = Math.max(
    0,
    Math.min(1, lerp(props.scale.scaleAtMinCharacters, props.scale.scaleAtMaxCharacters, factor)),
  )
  return interpolatedFactor !== 1 ? interpolatedFactor : undefined
})
</script>

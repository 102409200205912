<template>
  <PortalModal
    :title="title"
    :description="description"
    :size="size"
    @close="$emit('close')"
  >
    <template #body-and-footer>
      <form @submit.prevent="submit">
        <div>
          <slot
            :value="value"
            :fields="fields"
            :disabled="disabled"
            :errors="errors"
            :get-error="getError"
            :get-errors-with-prefix="getErrorsWithPrefix"
          >
            <BlockFormFields
              :value="value"
              :fields="fields"
              :disabled="disabled"
              :errors="errors"
              @update:value="$emit('update:value', $event)"
            >
              <template
                v-for="(_, name) in $slots"
                #[name]="scopedProps"
              >
                <slot
                  :name="name"
                  v-bind="scopedProps"
                />
              </template>
            </BlockFormFields>
          </slot>
        </div>
        <div class="tw-flex tw-justify-between tw-mt-6">
          <div class="tw-flex tw-items-center">
            <slot name="footerLeft" />
          </div>
          <div class="tw-flex items-center">
            <LuiButton
              type="button"
              emphasis="low"
              class="tw-mr-4"
              :disabled="disabled"
              @click="$emit('close')"
            >
              {{ $t('global.close') }}
            </LuiButton>
            <LuiButton
              v-tooltip="submitButtonTooltip"
              v-bind="submitButtonProps"
              :disabled="disabled || submitButtonProps?.disabled"
              :loading="loading || submitButtonProps?.loading"
              type="submit"
            >
              {{ submitButtonText || $t('global.save') }}
            </LuiButton>
          </div>
        </div>
      </form>
    </template>
  </PortalModal>
</template>

<script lang="ts" setup>
import type { LuiIconName } from '@loomispay/loomis-ui'
import { ref } from 'vue'

import BlockFormFields from '@/components/FormFields/Block.vue'
import PortalModal from '@/components/Modals/Templates/PortalModal.vue'
import { useValidationError } from '@/composables/useValidationError'
import http from '@/http'
import notify from '@/notify'
import { handleError } from '@/shared/errorService'

const { errors, catchValidationErrors, resetErrors, getError, getErrorsWithPrefix } = useValidationError()

defineExpose({
  errors,
  catchValidationErrors,
  resetErrors,
  getError,
})

const props = withDefaults(
  defineProps<{
    title: string
    description?: string
    fields?: any[]
    disabled?: boolean
    horizontal?: boolean
    value: any
    method?: 'post' | 'put'
    url?: string
    successMessage?: string
    formatRequestData?: (value: Record<string, any>) => any
    submitPromise?: () => Promise<any> | any
    submitButtonText?: string
    submitButtonTooltip?: string
    submitButtonProps?: {
      disabled?: boolean
      loading?: boolean
      icon?: LuiIconName
    }
    size?: 'small' | 'large'
  }>(),
  {
    fields: () => [],
    url: undefined,
    description: undefined,
    method: 'post',
    successMessage: undefined,
    submitButtonText: undefined,
    submitButtonTooltip: undefined,
    formatRequestData: (value: Record<string, any>) => value,
    submitButtonProps: undefined,
    submitPromise: undefined,
    size: undefined,
  },
)

const emit = defineEmits(['saved', 'update:value', 'cancel', 'close', 'submit'])

const loading = ref(false)

const submit = async () => {
  /*
   * If the url prop is not defined we simply emit submit, so the parent component can handle it.
   */
  if (!props.url && !props.submitPromise) {
    emit('submit')
    return
  }
  resetErrors()
  try {
    loading.value = true
    let response
    /*
     * If the submitPromise prop is defined we use it instead of the URL prop.
     */
    if (props.submitPromise) {
      response = await props.submitPromise()
    } else {
      response = await http.request(props.method, props.url!, props.formatRequestData(props.value))
    }
    if (props.successMessage) {
      notify.success(props.successMessage)
    }
    /**
     * If the response is a BaseApiResponse we emit the saved event with the data property.
     * If anything else we just emit the entire response.
     */
    emit('saved', response?.data?.data || response)
    emit('close')
  } catch (error) {
    if (!catchValidationErrors(error)) {
      handleError(error)
    }
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <FormGroup v-bind="{ topHelpText, optional, link, name, label, helpText, error }">
    <LuiInput
      v-bind="{ name, disabled, prefix, suffix, prefixIcon, suffixIcon, inputAttrs }"
      :id="name"
      ref="input"
      :has-error="!!error"
      :placeholder="placeholder || label"
      :model-value="value"
      @update:model-value="$emit('update:value', $event)"
    />
  </FormGroup>
</template>

<script setup lang="ts">
import { type LuiIconName, LuiInput } from '@loomispay/loomis-ui'
import { type Ref, ref } from 'vue'
import type { ComponentExposed } from 'vue-component-type-helpers'

import type { FormGroupProps } from '@/interfaces/Frontend/FormGroup'

import FormGroup from '@/components/FormGroups/Block/BlockFormGroup.vue'
import { useFormGroup } from '@/composables/useFormGroup'

interface Props extends FormGroupProps {
  value?: string | number | null
  placeholder?: string | null
  prefix?: string
  suffix?: string
  prefixIcon?: LuiIconName
  suffixIcon?: LuiIconName
  inputAttrs?: Record<string, unknown>
}

const props = withDefaults(defineProps<Props>(), {
  value: '',
  placeholder: '',
  prefix: undefined,
  suffix: undefined,
  prefixIcon: undefined,
  suffixIcon: undefined,
  inputAttrs: () => ({}),
})

defineEmits<{
  'update:value': [string]
}>()

const input = ref<ComponentExposed<typeof LuiInput> | null>(null)

useFormGroup(props, input as Ref<HTMLElement | null>)
</script>

import { routeNames as administrationRouteNames } from '@/router/administration-routes'
import { routeNames as applicationRouteNames } from '@/router/application-routes'
import { routeNames as cashRouteNames } from '@/router/cash-routes'
import { routeNames as financesRouteNames } from '@/router/finances-routes'
import { routeNames as merchantsRouteNames } from '@/router/merchants-routes'
import { routeNames as packagesRouteNames } from '@/router/packages-routes'
import { routeNames as productsRouteNames } from '@/router/products-routes'
import { routeNames as reportsRouteNames } from '@/router/reports-routes'
import { routeNames as reservationsRouteNames } from '@/router/reservations-routes'
import { routeNames as salesChannelsRouteNames } from '@/router/sales-channels-routes'
import { routeNames as systemRouteNames } from '@/router/system-routes'
import { routeNames as tablesRouteNames } from '@/router/tables-routes'

export const RouteNames = {
  HOME: 'HOME',
  NOT_FOUND: 'NOT_FOUND',
  GENERIC_ERROR: 'GENERIC_ERROR',
  LOGIN: 'LOGIN',
  LOGIN_UPDATE_DETAILS: 'LOGIN_UPDATE_DETAILS',
  INTERSTITIAL_2FA: 'INTERSTITIAL_2FA',
  DASHBOARD: 'DASHBOARD',
  SETTINGS: 'SETTINGS',
  ...administrationRouteNames,
  ...applicationRouteNames,
  ...cashRouteNames,
  ...financesRouteNames,
  ...merchantsRouteNames,
  ...packagesRouteNames,
  ...productsRouteNames,
  ...reportsRouteNames,
  ...reservationsRouteNames,
  ...salesChannelsRouteNames,
  ...systemRouteNames,
  ...tablesRouteNames,
}

<script setup lang="ts">
const props = defineProps<{
  black?: boolean
}>()
</script>

<template>
  <img
    class="tw-w-30"
    :src="props.black ? '/img/loomis_pay_logo.svg' : '/img/loomis_pay_logo_white_text.svg'"
    :alt="$t('navigation.alt.loomisPayLogo')"
  />
</template>

import { type RemovableRef, StorageSerializers, useStorage } from '@vueuse/core'
import { defineStore, storeToRefs } from 'pinia'
import { v4 as uuid } from 'uuid'
import { computed, ref } from 'vue'

import type { PortalMessage, PortalMessageAttributes } from '@/interfaces/PortalMessage'

import { fetchMessages } from '@/api/messages-api'
import notify from '@/notify'
import { hash } from '@/shared/hash'

import { useMerchantsStore } from './merchants'

export const useMessagesStore = defineStore('messages', () => {
  const messages = ref<PortalMessage[]>([])
  const discardedMessageHashes: RemovableRef<Set<string>> = useStorage(
    'discardedMessageHashes',
    new Set(),
    localStorage,
    {
      serializer: StorageSerializers.set,
    },
  )

  const loadMessages = async () => {
    try {
      const messagesResponse = await fetchMessages()
      messages.value = messagesResponse.data.data
    } catch (error: any) {
      const message = error.response?.data?.message
      message && notify.error(message)
    }
  }

  // Add a custom message
  const addMessage = (attrs: Partial<PortalMessageAttributes>) => {
    // Check if all required fields are present and fill them out if necessary
    const newMessage = {
      type: 'messages',
      attributes: {
        level: attrs?.level || 'info',
        content: attrs?.content || '',
        // This optional id ensures uniqueness of hash generated
        // from manually added message atrributes object
        interim_message_id: attrs?.interim_message_id || null,

        button_event: attrs?.button_event || null,
        button_target: attrs?.button_target || null,
        button_text: attrs?.button_text || null,
        button_url: attrs?.button_url || null,
      },
      id: uuid(),
      meta: {},
    } as PortalMessage
    messages.value.push(newMessage)
  }

  const discardMessage = (message: PortalMessage) => {
    discardedMessageHashes.value.add(hash(message.attributes))
  }

  const visibleMessages = computed(() => {
    const { selectedMerchant } = storeToRefs(useMerchantsStore())
    return (
      messages.value
        .filter((message) => {
          return !discardedMessageHashes.value.has(hash(message.attributes))
        })
        // country should either be null or match the country for selectedMerchant
        .filter((message) => {
          return (
            !message.attributes.country || message.attributes.country === selectedMerchant.value?.attributes.country
          )
        })
    )
  })

  const resetMessages = () => {
    messages.value = []
  }

  return {
    messages,
    visibleMessages,
    addMessage,
    discardMessage,
    loadMessages,
    resetMessages,
  }
})

export enum CardType {
  VISA = 'visa',
  MasterCard = 'mastercard',
  Dankort = 'dankort',
  Maestro = 'maestro',
  AMEX = 'amex',
  Forbrugsforeningen = 'forbrugsforeningen',
  GiftCard = 'gift-card',
  SparXpress = 'sparxpress',
  BankAxept = 'bankaxept',
}

export const payfacCardTypes = [CardType.VISA, CardType.MasterCard, CardType.Maestro, CardType.AMEX, CardType.BankAxept, CardType.Dankort]

export const getVerboseNameFromCardType = (cardType: CardType) => {
  switch (cardType) {
    case CardType.VISA:
      return 'Visa'
    case CardType.MasterCard:
      return 'MasterCard'
    case CardType.Dankort:
      return 'Dankort'
    case CardType.Maestro:
      return 'Maestro'
    case CardType.AMEX:
      return 'American Express'
    case CardType.Forbrugsforeningen:
      return 'Forbrugsforeningen'
    case CardType.GiftCard:
      return 'Gavekort'
    case CardType.SparXpress:
      return 'Spar Xpress'
    case CardType.BankAxept:
      return 'BankAxept'
  }
}
